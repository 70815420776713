import { FC, Dispatch, SetStateAction, useState, useEffect } from 'react';
import type { RadioChangeEvent } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { cancelSubscription } from 'data/actions/subscriptions';
import { modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import closeImagePath from 'assets/close-icon.svg';
import useKeyPress from 'hooks/useKeyPress';
import CommonInput from 'components/common/input';
import CommonButton from 'components/common/button';
import { CloseIcon } from '../../baseModal/styles';
import { Container, Title, RadioGroupContainer, RadioItem, InputContainer } from '../styles';
import { Analytics } from 'services/analytics';

const ReasonsStep: FC<{ setStep: Dispatch<SetStateAction<string>> }> = ({ setStep }) => {
  const dispatch = useDispatch();
  const options = useSelector(modalOptionsSelector);
  const { t } = useTranslation();
  const [value, setValue] = useState(1);
  const [message, setMessage] = useState('');

  useEffect(() => {
    void Analytics.sendEvent({
      event: 'cancel_plan_reason_screen',
    });
  }, []);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    const data: { answer: string; other?: string } = {
      answer: t(`cancel_plan_modal.reasons.${value}`),
    };
    if (message) data.other = message;
    void Analytics.sendEvent({
      event: 'cancel_plan_reason_submit_tap',
      data,
    });
    dispatch(cancelSubscription(options?.subscriptionId));
    setStep('final');
  };

  const handleClose = () => {
    void Analytics.sendEvent({
      event: 'cancel_plan_close_tap',
    });
    void Analytics.sendEvent({
      event: 'keep_plan_tap',
    });
    dispatch(toggleModal({ visible: false }));
  };

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleSubmit });

  return (
    <Container>
      <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
      <Title>
        {t('cancel_plan_modal.almost_done')}
        <br />
        {t('cancel_plan_modal.why_leaving')}
      </Title>
      <RadioGroupContainer onChange={onChange} value={value}>
        <RadioItem value={1}>{t('cancel_plan_modal.reasons.0')}</RadioItem>
        <RadioItem value={2}>{t('cancel_plan_modal.reasons.1')}</RadioItem>
        <RadioItem value={3}>{t('cancel_plan_modal.reasons.2')}</RadioItem>
        <RadioItem value={4}>{t('cancel_plan_modal.reasons.3')}</RadioItem>
        <RadioItem value={5}>{t('cancel_plan_modal.reasons.4')}</RadioItem>
        <RadioItem value={8}>{t('cancel_plan_modal.reasons.5')}</RadioItem>
        <RadioItem value={7}>{t('cancel_plan_modal.other')}</RadioItem>
      </RadioGroupContainer>
      {value === 7 && (
        <InputContainer>
          <CommonInput
            suggestion={t('cancel_plan_modal.please_keep_words')}
            label={t('cancel_plan_modal.write_your_reason')}
            placeholder={t('global.text')}
            value={message}
            onChange={(value: string) => setMessage(value)}
          />
        </InputContainer>
      )}
      <CommonButton style={{ marginTop: '35px' }} type="primary" onClick={handleSubmit}>
        {t('global.submit')}
      </CommonButton>
    </Container>
  );
};

export default ReasonsStep;
