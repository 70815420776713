import { Radio } from 'antd';
import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 44px 50px 20px 50px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
    height: auto;
  }
`;

export const FinalStepContainer = styled(Container)`
  padding: 44px 40px 40px 40px;
  width: 530px;
  max-height: 318px;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
    max-height: fit-content;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
`;

export const OwlImage = styled.img`
  width: 65px;
  height: 79.999px;
`;

export const Title = styled(CommonTextH5)`
  color: var(--Dark-Dark, #111928);
  text-align: center;
`;

export const TitleFinalStep = styled(Title)`
  margin: 35px 0 15px 0;
  color: var(--Dark-Dark, #111928);
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 32.5px */
`;

export const RadioGroupContainer = styled(Radio.Group)`
  display: flex !important;
  flex-direction: column;
  margin-top: 35px !important;
  justify-content: flex-start;
  width: 100%;
  gap: 5px;
`;

export const RadioItem = styled(Radio)`
  padding: 10px 0 !important;

  .ant-radio {
    margin-right: 10px !important;

    .ant-radio-inner {
      width: 20px;
      height: 20px;
      font-family: Lato;
      border-color: #4c5e7f;
    }

    .ant-radio-inner .ant-radio-checked {
      background: #3758f9;
      border: #3758f9;
    }
  }

  span {
    color: var(--Colors-black-grey-white-main, #4c5e7f);
    /* Body/Reg */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    padding: 0 !important;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const Description = styled.div`
  color: var(--Colors-black-grey-white-main, #1d1d1d);
  text-align: center;
  /* Body/Reg */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  max-width: 400px;
`;
