import { FC, useEffect, useState } from 'react';
import { Upload } from 'antd';

import useDisableScroll from 'hooks/useDisableScroll';
import openNotification from 'components/commonNotification';
import { useDispatch, useSelector } from 'react-redux';
import { modalOptionsSelector } from 'data/selectors/modals';
import { ENotification } from 'ts/interfaces/common/notification';
import useFileUploadAndConvert from 'hooks/useFileUploadAndConvert';
import { toggleModal } from 'data/actions/modals';

import { ModalContainer } from '../baseModal/styles';
import { Container, Title } from './styles';
import useFileUploadAndEdit from 'hooks/useFileUploadAndEdit';
import { EServiceType } from 'ts/interfaces/services/service';
import { EModalTypes } from 'ts/enums/modal.types';
import useFileUploadAndCompress from 'hooks/useFileUploadAndCompress';
import useFileUploadAndSplit from 'hooks/useFileUploadAndSplit';
import { pushMergeDocument } from 'data/actions/documents';
import { useLocation } from 'react-router-dom';
import { mergeDocumentsListSelector } from 'data/selectors/documents';
import { Analytics } from 'services/analytics';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useTranslation } from 'react-i18next';

const DragAndDropModal: FC = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const mergeDocumentsList: any = useSelector(mergeDocumentsListSelector());
  const options = useSelector(modalOptionsSelector);

  const service = options?.service;
  const { handleUploadFile } = useFileUploadAndConvert({ service });
  const { handleUploadFile: handleUploadEditFile } = useFileUploadAndEdit({
    service,
  });
  const { handleUploadFile: handleUploadCompressFile } = useFileUploadAndCompress({ service });
  const { handleUploadFile: handleUploadSplitFile } = useFileUploadAndSplit({
    service,
  });

  const [fileData, setFileData] = useState<(File & { uid: string }) | null>(null);
  const [countFiles, setCountFiles] = useState<number>(0);
  let sendNotification = false;

  useDisableScroll();

  const handleBeforeUpload = (
    file: (File & { uid: string }) | null,
    FileList?: File[] & { uid: string }[]
  ) => {
    let isErrorMessage = validateFile(file);
    if (mergeDocumentsList?.length + FileList?.length > 15)
      isErrorMessage = t('global.upload_up_to');
    if (FileList?.length && location?.pathname?.includes('/merge-pdf'))
      setCountFiles(FileList?.length);

    if (isErrorMessage || !file) {
      dispatch(toggleModal({ visible: false }));

      if (!sendNotification) {
        sendNotification = true;
        openNotification({
          message: isErrorMessage,
          type: ENotification.ERROR,
        });
        Analytics.sendEvent({ event: 'complete_merge_error_message' });
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    // fix drag html elements on site (png, svg)
    const listener = () => {
      handleBeforeUpload(fileData);
    };

    window.addEventListener('drop', listener);

    return () => {
      window.removeEventListener('drop', listener);
    };
  });

  const validateFileName = (name: string) => {
    const fileFormat: string = name?.split('.')?.pop() || '';
    return service?.availableFormats?.includes(`.${fileFormat}`);
  };

  const validateFile = (file: File | null) => {
    let errorMessage = '';
    if (!file || !validateFileName(file?.name)) errorMessage = t('global.wrong_file_format');
    else if (file.size > 100 * 1024 * 1024) errorMessage = t('global.file_exceeded_limit');
    return errorMessage;
  };

  const handleOnDragLeave = (event: any) => {
    if (!event?.relatedTarget) dispatch(toggleModal({ visible: false }));
  };

  useEffect(() => {
    if (location?.pathname?.includes('/merge-pdf')) {
      if (countFiles && mergeDocumentsList?.length === countFiles) {
        dispatch(toggleModal({ visible: false }));
        navigate('/complete-merge');
      }
    }
  }, [navigate, location, mergeDocumentsList, countFiles, dispatch]);

  return (
    <ModalContainer onDragLeave={(event) => handleOnDragLeave(event)}>
      <Upload
        name="file"
        type="drag"
        showUploadList={false}
        multiple={service?.serviceType === EServiceType.MERGER}
        maxCount={service?.serviceType === EServiceType.MERGER ? 15 : 1}
        customRequest={(file: any) => {
          setFileData(file?.file);
          Analytics.sendEvent({
            event: 'upload_ﬁle_tap',
            data: { method: 'drag_and_drop' },
          });

          if (service?.serviceType === EServiceType.PDF_CONVERTOR) {
            return dispatch(
              toggleModal({
                visible: true,
                type: EModalTypes.CHOOSE_FORMAT_AND_CONVERT,
                options: { file: file?.file, service },
              })
            );
          } else if (service?.serviceType === EServiceType.COMPRESSOR)
            return handleUploadCompressFile(file?.file);
          else if (service?.serviceType === EServiceType.MERGER)
            return dispatch(pushMergeDocument({ file: file.file, thumbnail: null }));
          else if (service?.serviceType === EServiceType.EDITOR)
            return handleUploadEditFile(file?.file);
          else if (service?.serviceType === EServiceType.SPLITTER)
            return handleUploadSplitFile(file?.file);
          handleUploadFile(file?.file);
        }}
        beforeUpload={(file: File & { uid: string }, FileList: File[] & { uid: string }[]) =>
          handleBeforeUpload(file, FileList)
        }
      >
        <Container>
          <Title>{t('global.drop_your_file_here')}</Title>
        </Container>
      </Upload>
    </ModalContainer>
  );
};

export default DragAndDropModal;
