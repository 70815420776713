import styled from 'styled-components';
import { ButtonProps } from 'ts/types/button.props';
import { ButtonHeight } from 'ts/enums/styles';

export const ButtonContainer = styled.button<ButtonProps>`
  display: flex;
  width: ${(props) => props?.width || '100%'};
  padding: 12px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  cursor: ${(props) => (props?.disabled ? 'not-allowed' : 'pointer')};
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  outline: none;
  min-width: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.size ? props.size : ButtonHeight.LARGE)};
  border: none;
  border: ${(props) => {
    if (props.disabled || !props?.type) return '1px solid #E5E7EB';
    switch (props?.type) {
      case 'primary':
        return '1px solid #3758F9';
      case 'danger':
        return '1px solid #E10E0E';
      case 'secondary':
        return '1px solid #3758F9';
      case 'tertiary':
        return '1px solid #DFE4EA';
      default:
        return '';
    }
  }};

  background: ${(props) => {
    if (props.disabled || !props?.type) return '#E5E7EB';
    switch (props?.type) {
      case 'primary':
        return '#3758F9';
      case 'danger':
        return '#E10E0E';
      case 'secondary':
      case 'tertiary':
        return '#FFF';
      default:
        return '';
    }
  }};
  color: ${(props) => {
    if (props?.disabled || !props?.type) return '#6B7280';
    switch (props?.type) {
      case 'primary':
      case 'danger':
        return '#FFF';
      case 'secondary':
        return '#3758F9';
      case 'tertiary':
        return '#4C5E7F';
      default:
        return '#4C5E7F';
    }
  }};

  &:hover {
    background: ${(props) => {
      if (props.disabled || !props?.type) return '#E5E7EB';
      switch (props?.type) {
        case 'primary':
          return '#2443D7';
        case 'danger':
          return '#C90909';
        case 'secondary':
        case 'tertiary':
          return '#EBEEFF';
        default:
          return '';
      }
    }};

    border: ${(props) => {
      if (props.disabled || !props?.type) return '1px solid #E5E7EB';
      switch (props?.type) {
        case 'primary':
          return '1px solid #2443D7';
        case 'danger':
          return '1px solid #C90909';
        case 'secondary':
          return '1px solid #3758F9';
        case 'tertiary':
          return '1px solid #DFE4EA';
        default:
          return '';
      }
    }};

    color: ${(props) => {
      if (props?.disabled || !props?.type) return '#6B7280';
      switch (props?.type) {
        case 'primary':
        case 'danger':
          return '#FFF';
        case 'secondary':
          return '#3758F9';
        case 'tertiary':
          return '#4C5E7F';
        default:
          return '#4C5E7F';
      }
    }};
    outline: none;
  }
  &:focus {
    transition: all 0.2s ease-in-out 0s;
  }
`;

export const LinkButtonContainer = styled.button<ButtonProps>`
  border: none;
  background: none;
  color: #5766eb;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${(props) => props.disabled && 'pointer-events: none;'}
`;
