import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import closeImagePath from 'assets/close-icon.svg';
import emailImgPath from 'assets/send-email-icon.svg';
import { toggleModal } from 'data/actions/modals';
import ModalLayout from 'components/modals/baseModal';
import { modalOptionsSelector } from 'data/selectors/modals';
import { Analytics } from 'services/analytics';
import { CloseIcon } from '../baseModal/styles';
import { Container, Title, Description, ImageContainer, Image, Email } from './styles';

const CheckYourEmailModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const options = useSelector(modalOptionsSelector);

  useEffect(() => {
    Analytics.sendEvent({ event: 'recover_pass_email_sent_modal_view' });
  }, []);

  const handleClose = () => {
    Analytics.sendEvent({ event: 'recover_pass_email_sent_close_tap' });
    dispatch(toggleModal({ visible: false }));
  };

  return (
    <ModalLayout>
      <Container>
        <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
        <ImageContainer>
          <Image alt="" src={emailImgPath} />
        </ImageContainer>
        <Title>{t('check_email_modal.title')}</Title>
        <Description>{t('check_email_modal.description')}</Description>
        <Email>{options?.email}</Email>
      </Container>
    </ModalLayout>
  );
};

export default CheckYourEmailModal;
