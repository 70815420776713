import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import GoogleIcon from 'assets/google.svg';
import LogoImage from 'assets/logo.svg';
import CommonButton from 'components/common/button';
import EmailAutoCompleteInput from 'components/common/inputEmailAutoComplete';
import CommonLink from 'components/common/link';
import openNotification from 'components/commonNotification';
import { toggleModal } from 'data/actions/modals';
import { createAnonymousUser, createUserReveal, getUser } from 'data/actions/user';
import { modalOptionsSelector } from 'data/selectors/modals';
import buildOauthRequestUrl from 'helpers/buildOauthRequestUrl';
import { isEmailValid } from 'helpers/validation';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { GoogleButton } from 'layouts/authForm/styles';
import { Analytics } from 'services/analytics';
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';

import useDisableScroll from 'hooks/useDisableScroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { beforeUnloadCallback } from 'utils/beforeUnload';
import { CloseIcon, ModalContainer } from '../baseModal/styles';
import {
  Container,
  ContentWrapper,
  Description,
  InputContainer,
  InputsWrapper,
  LabeledDivider,
  LoginLinkContainer,
  Logo,
  LogoContainer,
  SignUpLink,
  Title,
} from './styles';

const EnterEmailAddressModal: FC = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  useDisableScroll();

  const { handleConvertDocument, servicePath } = useSelector(modalOptionsSelector);

  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState({ email: '' });

  useEffect(() => {
    Analytics.sendEvent({ event: 'email_enter_modal_view' });
  }, []);

  const validateEmail = (email: string) => {
    if (!isEmailValid(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t('global.please_enter_valid_email'),
      }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  };

  const onFailedSignUp = () => {
    Analytics.sendEvent({
      event: 'email_already_exist_popup',
    });
    openNotification({
      message: (
        <div>
          {t('auth_form.this_email_already_exist')}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(toggleModal({ visible: true, type: EModalTypes.AUTH_FORM }))}
          >
            {' '}
            {t('auth_form.log_in')}
          </span>
        </div>
      ),
      type: ENotification.ERROR,
      duration: 10,
    });
  };

  const onSuccessSignUp = () => {
    const handleNavigate = (route: string) => {
      dispatch(toggleModal({ visible: false }));
      navigate(route);
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };
    window.onbeforeunload = null;

    dispatch(getUser({ onFailed: () => dispatch(createAnonymousUser()) }));

    void Analytics.sendEvent({
      event: 'email_enter_tap',
      data: { email, type: 'email' },
    });

    if (typeof handleConvertDocument === 'function') {
      handleConvertDocument();
      return dispatch(toggleModal({ visible: false }));
    }
    handleNavigate('/app/dashboard');
    dispatch(toggleModal({ visible: false }));
  };

  const handleDownLoadFile = () => {
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) return;

    dispatch(createUserReveal(email, onSuccessSignUp, onFailedSignUp));
  };

  const connectGmailAccount = (e: any) => {
    Analytics.sendEvent({ event: 'continue_google_tap' });
    e.preventDefault();
    window.onbeforeunload = null;
    const url = buildOauthRequestUrl();
    window.location.href = url;
  };

  const handleLogin = () => {
    dispatch(
      toggleModal({
        type: EModalTypes.AUTH_FORM,
        visible: true,
        options: {
          handleConvertDocument,
          servicePath,
          reveal: true,
        },
      })
    );
  };

  const handleModalClose = () => {
    Analytics.sendEvent({
      event: 'enter_email_close_tap',
    });
    window.onbeforeunload = beforeUnloadCallback;
    dispatch(toggleModal({ visible: false }));
  };

  const descriptionElement = useMemo(
    () => (
      <Description>
        {t('auth_form.by_creating_account_means')}{' '}
        <CommonLink size="13.4px" to="/terms" target="_blank">
          {t('global.terms_and_conditions')}
        </CommonLink>
        , {t('auth_form.and_our')}{' '}
        <CommonLink size="13.4px" to="/privacy-policy" target="_blank">
          {t('global.privacy_policy')}
        </CommonLink>
      </Description>
    ),
    [t]
  );

  return (
    <ModalContainer>
      <Container>
        <LogoContainer>
          <Logo src={LogoImage} alt="TheBestPDF logo" />
          <CloseIcon onClick={() => handleModalClose()} src={closeImagePath} alt="" />
        </LogoContainer>

        <ContentWrapper>
          <Title>{t('global.to_download_file')}</Title>

          <GoogleButton onClick={connectGmailAccount}>
            <LazyLoadImage src={GoogleIcon} alt="google-icon" />
            {t('auth_form.continue_with_google')}
          </GoogleButton>
          <LabeledDivider>
            <span>{t('global.or')}</span>
          </LabeledDivider>

          <InputsWrapper>
            <InputContainer>
              <EmailAutoCompleteInput
                onChange={(value: string) => setEmail(value)}
                value={email}
                placeholder={t('auth_form.email')}
                label={t('auth_form.email_address')}
                error={errors.email}
              />
            </InputContainer>
          </InputsWrapper>

          <CommonButton type="primary" onClick={() => handleDownLoadFile()}>
            {t('global.download_file')}
          </CommonButton>

          {descriptionElement}

          <LoginLinkContainer>
            <div>
              <span>{t('auth_form.have_an_account')}</span>
              <SignUpLink onClick={handleLogin}>{t('auth_form.log_in')}</SignUpLink>
            </div>
          </LoginLinkContainer>
        </ContentWrapper>
      </Container>
    </ModalContainer>
  );
};

export default EnterEmailAddressModal;
