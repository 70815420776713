import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from 'locales/de/common.json';
import en from 'locales/en/common.json';
import fr from 'locales/fr/common.json';
import it from 'locales/it/common.json';

export const DEFAULT_LANGUAGE: SupportedLanguage = 'en';

export type SupportedLanguage = 'en' | 'fr' | 'de' | 'it';

export const languages: SupportedLanguage[] = [DEFAULT_LANGUAGE, 'fr', 'de', 'it'];

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  de: {
    translation: de,
  },
  it: {
    translation: it,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    supportedLngs: languages,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ['path', 'navigator'], // ["path", "navigator", "querystring", 'cookie'],
      lookupFromPathIndex: 0,
      lookupQuerystring: 'lng',
      // caches: ["cookie"],
    },
  });

export default i18n;
