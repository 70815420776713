import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 470px;
  height: 228px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    height: auto;
    margin: 0 15px;
  }
`;

export const Description = styled.div`
  color: var(--Text-color-subtitle, #4c5e7f);
  text-align: center;

  /* Body Large/Reg */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  margin-top: 22px;
  max-width: 400px;
`;
