import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import GoogleIcon from 'assets/google.svg';
import LogoImage from 'assets/logo.svg';
import CommonButton from 'components/common/button';
import CommonInput from 'components/common/input';
import CommonInputPassword from 'components/common/inputPassword';
import CommonLink from 'components/common/link';
import openNotification from 'components/commonNotification';
import { toggleModal } from 'data/actions/modals';
import { getUserSubscription } from 'data/actions/subscriptions';
import { createAnonymousUser, getUser, signIn } from 'data/actions/user';
import { modalOptionsSelector } from 'data/selectors/modals';
import buildOauthRequestUrl from 'helpers/buildOauthRequestUrl';
import { isEmailValid, isValidPassword } from 'helpers/validation';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import useKeyPress from 'hooks/useKeyPress';
import { Analytics } from 'services/analytics';
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';
import {
  CloseIcon,
  Container,
  ContentWrapper,
  Description,
  GoogleButton,
  Header,
  InputContainer,
  InputsWrapper,
  LabeledDivider,
  Logo,
  LogoContainer,
  SignUpLink,
  Title,
} from './styles';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AuthFormStep } from './types.d';

const SignIn: FC<{ setStep: Dispatch<SetStateAction<AuthFormStep>> }> = ({ setStep }) => {
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { handleConvertDocument, servicePath, reveal } = useSelector(modalOptionsSelector);
  const passwordInputRef = useRef<any>();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState({ password: '', email: '' });

  useEffect(() => {
    Analytics.sendEvent({ event: 'log_in_page_view' });
  }, []);

  const handleNavigate = (route: string) => {
    dispatch(toggleModal({ visible: false }));
    navigate(route);
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  const connectGmailAccount = (e: any) => {
    Analytics.sendEvent({ event: 'login_confirm_tap', data: { type: 'google' } });
    e.preventDefault();
    const url = buildOauthRequestUrl();
    window.location.href = url;
  };

  const validatePassword = (password: string) => {
    const errorMessage = isValidPassword(password);
    setErrors((prev) => ({ ...prev, password: errorMessage }));
    return !errorMessage?.length;
  };

  const validateEmail = (email: string) => {
    if (!isEmailValid(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t('global.please_enter_valid_email'),
      }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  };

  const onSuccess = () => {
    dispatch(getUser({ onFailed: () => dispatch(createAnonymousUser()) }));

    if (typeof handleConvertDocument === 'function') {
      const callback = (subscription: any) => {
        dispatch(toggleModal({ visible: false }));
        return handleConvertDocument(subscription);
      };
      dispatch(getUserSubscription(callback, callback));
      return navigate(servicePath || '/app/dashboard');
    }
    handleNavigate('/app/dashboard');
    dispatch(toggleModal({ visible: false }));
  };

  const onFailed = () => {
    openNotification({
      message: t('global.incorrect_password_or_mail'),
      duration: 5,
      type: ENotification.ERROR,
      description: '',
    });
  };

  const handleSignIn = () => {
    const isValidEmail = validateEmail(email);

    if (passwordInputRef?.current && isValidEmail) {
      passwordInputRef?.current?.focus();
    }

    const isValidPass = validatePassword(password);
    if (!isValidPass || !isValidEmail) return;
    Analytics.sendEvent({ event: 'log_in_tap' });
    dispatch(signIn(email, password, onSuccess, onFailed));
  };

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleSignIn });

  const descriptionElement = useMemo(
    () => (
      <Description>
        {t('auth_form.by_creating_account_means')}{' '}
        <CommonLink size="13.4px" to="/terms" target="_blank">
          {t('global.terms_and_conditions')}
        </CommonLink>
        , {t('auth_form.and_our')}{' '}
        <CommonLink size="13.4px" to="/privacy-policy" target="_blank">
          {t('global.privacy_policy')}
        </CommonLink>
      </Description>
    ),
    [t]
  );

  const logoElement = useMemo(
    () => (
      <LogoContainer>
        <Logo src={LogoImage} alt="TheBestPDF logo" />
        <CloseIcon
          onClick={() => dispatch(toggleModal({ visible: false }))}
          src={closeImagePath}
          alt=""
        />
      </LogoContainer>
    ),
    [dispatch]
  );

  const handleNavigateSignUp = useCallback(() => {
    if (reveal) {
      return dispatch(
        toggleModal({
          type: EModalTypes.ENTER_EMAIL_ADDRESS,
          visible: true,
          options: {
            handleConvertDocument,
            servicePath,
          },
        })
      );
    }

    setStep(AuthFormStep['sign-up']);
  }, [dispatch, reveal, servicePath, setStep, handleConvertDocument]);

  const headerElement = useMemo(
    () => (
      <>
        <Header>
          <div>
            <span>{t('auth_form.new_user')}</span>
            <SignUpLink onClick={handleNavigateSignUp}>{t('auth_form.sign_up')}</SignUpLink>
          </div>
        </Header>

        <Title>{t('auth_form.good_to_see_you_back')}</Title>

        <GoogleButton onClick={connectGmailAccount}>
          <LazyLoadImage src={GoogleIcon} alt="google-icon" />
          {t('auth_form.continue_with_google')}
        </GoogleButton>
        <LabeledDivider>
          <span>{t('global.or')}</span>
        </LabeledDivider>
      </>
    ),
    [handleNavigateSignUp, t]
  );

  return (
    <Container>
      {logoElement}
      <ContentWrapper>
        {headerElement}
        <InputsWrapper>
          <InputContainer>
            <CommonInput
              onChange={(value: string) => setEmail(value)}
              value={email}
              error={errors.email}
              placeholder={t('auth_form.email')}
              label={t('auth_form.email_address')}
              name="email"
              type="email"
            />
          </InputContainer>

          <InputContainer>
            <CommonInputPassword
              onChange={(value: string) => setPassword(value)}
              value={password}
              errorMessage={errors.password}
              placeholder={t('auth_form.password')}
              label={t('auth_form.password')}
              onForgotPassword={() => setStep(AuthFormStep['forgot-password'])}
              passwordInputRef={passwordInputRef}
              suggestion={t('auth_form.password_must_contain')}
            />
          </InputContainer>
        </InputsWrapper>

        <CommonButton type="primary" onClick={handleSignIn}>
          {t('auth_form.log_in')}
        </CommonButton>

        {descriptionElement}
      </ContentWrapper>
    </Container>
  );
};

export default SignIn;
