import { Progress } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import uploadingIconPath from 'assets/editor/upload-icon.svg';
import { updateDownloadProgress } from 'data/actions/documents';
import { uploadProgressSelector } from 'data/selectors/documents';
import { calculateProgress, useAnimatedProgress } from 'hooks/useAnimatedProgress';
import { Analytics } from 'services/analytics';
import { FileUploadingModalViewTypes } from 'services/analytics/events';
import ModalLayout from '../baseModal';
import {
  Container,
  ProgressCOntainer,
  ProgressValue,
  Title,
  UploadImage,
  UploadImageContainer,
} from './styles';

const ProgressEditFileModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const downloadProgress = useSelector(uploadProgressSelector());
  const animatedProgress = useAnimatedProgress(5);
  const progressValue = calculateProgress(animatedProgress, 0, 90);

  useEffect(() => {
    dispatch(updateDownloadProgress(progressValue));
  }, [dispatch, progressValue]);

  useEffect(() => {
    return () => {
      dispatch(updateDownloadProgress(0));
    };
  }, [dispatch]);

  useEffect(() => {
    Analytics.sendEvent({
      event: 'file_uploading_modal_view',
      data: {
        type: FileUploadingModalViewTypes.editFunnels,
      },
    });
  }, []);

  return (
    <ModalLayout>
      <Container>
        <UploadImageContainer>
          <UploadImage src={uploadingIconPath} alt="" />
        </UploadImageContainer>

        <ProgressCOntainer>
          <Title>{t('global.your_file_uploading')}</Title>
          <ProgressValue>{Math.floor(downloadProgress)}%</ProgressValue>
        </ProgressCOntainer>

        <Progress percent={Math.floor(downloadProgress)} showInfo={false} />
      </Container>
    </ModalLayout>
  );
};

export default ProgressEditFileModal;
