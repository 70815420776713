import { Input } from 'antd';
import styled from 'styled-components';

export const InputPassword = styled(Input.Password)<{ $errorMessage?: string }>`
  display: flex !important;
  height: auto;
  padding: 12px 20px !important;
  padding: 12px 16px 12px 12px !important;
  align-items: end;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 6px !important;
  background: var(--Primary-white, #fff) !important;
  width: 100%;
  color: var(--Grey-80, #354562) !important;
  margin-top: 10px !important;
  font-family: Lato !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-style: normal;
  line-height: 24px !important;
  border: ${(props) =>
    props?.$errorMessage ? '1px solid#F23030 !important' : '1px solid#DFE4EA !important'};

  &:active,
  &:focus,
  &:hover {
    border: ${(props) =>
      props?.$errorMessage ? '1px solid#F23030 !important' : '1px solid#DFE4EA !important'};
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-input-group-addon {
    border: none !important;
    background: var(--Primary-white, #fff) !important;
    padding: 0 !important;
    line-height: 0 !important;
  }

  .ant-input-group-wrapper {
    padding: 12px 20px !important;
    color: var(--Text-secondary, #4a4e62);
  }

  .ant-input-affix-wrapper {
    border: none !important;
    font-size: 16px !important;
    font-family: Lato !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0 0 0 10px !important;

    &:active,
    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  input::placeholder {
    color: #9ca3af !important;
    /* Body/Reg */
    font-family: Lato !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
  }

  input {
    border: none !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 0 !important;
    outline: none !important;
    font-family: Lato !important;
    color: var(--Text-secondary, #4a4e62);
    font-family: Lato !important;
    font-weight: 400 !important;

    &:active,
    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;

export const InputLabel = styled.span`
  color: var(--Text-secondary, #354562);
  /* Body/Reg */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  color: #f23030;
  /* Caption/Reg */
  font-family: Lato;
  font-size: 13.4px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 21.44px */
`;

export const SuggestionMessage = styled.div<{ $errorMessage?: string }>`
  margin-top: 5px;
  color: ${(props) => (props?.$errorMessage ? '#F23030' : '#4B5563')};
  /* Caption/Reg */
  font-family: Lato;
  font-size: 13.4px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 21.44px */
`;

export const LockIcon = styled.img`
  cursor: pointer;
`;

export const ForgotContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ForgotPasswordButton = styled.div`
  color: var(--Primary-Color, #3758f9);
  text-align: right;
  /* Body/Reg */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 20.8px */
  cursor: pointer;
`;
