import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 20px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 510px;
  height: 195px;
  display: flex;
  gap: 22px;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: calc(100% - 30px);
    height: auto;
    margin: 0 15px;
  }
`;

export const Description = styled.div`
  color: var(--Text-color-subtitle, #4c5e7f);
  text-align: center;

  /* Desktop/Body/Reg */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  width: 470px;
  flex-shrink: 0;
  align-self: stretch;
  margin: 0 auto;

  @media (max-width: 760px) {
    width: 100%;
  }
`;
