import { createGlobalStyle } from 'styled-components';

// import MontserratItalic from './montserrat-italic-font.ttf';
import Montserrat from './montserrat-variable.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat'),
    url(${Montserrat}) format('ttf');
    font-style: normal;
  }
`;
