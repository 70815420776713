import CommonLink from 'components/common/link';
import styled from 'styled-components';

export const Container = styled.div<{ visible?: string }>`
  display: ${(props) => (props?.visible === 'true' ? 'flex' : 'none')};
  width: 100%;
  padding: 20px 40px;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  background: #f9faff;

  @media (max-width: 1080px) {
    padding: 20px 40px;
  }

  @media (max-width: 760px) {
    padding: 10px 15px;
  }
`;

export const Content = styled.div`
  color: var(--90, #354562);
  text-align: start;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  display: flex;
  align-items: start;
  max-width: 1360px;
  justify-content: space-between;
  width: 100%;

  a {
    text-decoration: none;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
  }

  img {
    margin: 5px;
    margin-left: 20px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  @media (max-width: 760px) {
    font-size: 13px;

    a {
      font-size: 13px;
    }
  }
`;

export const Link = styled(CommonLink)`
  text-decoration: underline !important;
`;
