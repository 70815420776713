import { AutoComplete } from 'antd';
import styled from 'styled-components';

export const InputAutocomplete = styled(AutoComplete)<{
  $errorMessage?: string;
}>`
  width: 100%;
  height: auto !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 6px !important;

  .ant-select-selector {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 6px !important;
    border: none !important;
  }

  .ant-select-selection-placeholder {
    color: #9ca3af !important;
    /* Body/Reg */
    font-family: Lato !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 130%; /* 20.8px */
  }

  input {
    border-radius: 6px !important;
    font-family: Lato !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    outline: none !important;
    box-shadow: none !important;
  }
`;
