import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import imagePath from 'assets/modal/await-watch-icon.svg';
import closeImagePath from 'assets/close-icon.svg';
import { FinalStepContainer, OwlImage, Title } from '../styles';
import { CloseIcon } from '../../baseModal/styles';
import { Analytics } from 'services/analytics';
import { useTranslation } from 'react-i18next';

const FinalStep: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    void Analytics.sendEvent({
      event: 'sent_file_modal_view',
    });
  }, []);

  const handleClose = () => {
    void Analytics.sendEvent({
      event: 'sent_file_close_tap',
    });
    dispatch(toggleModal({ visible: false }));
  };

  return (
    <FinalStepContainer>
      <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
      <OwlImage alt="" src={imagePath} />
      <Title>{t('free_access_modal.success_title')}</Title>
    </FinalStepContainer>
  );
};

export default FinalStep;
