import CommonButton from 'components/common/button';
import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px 44px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    height: auto;
    padding: 50px 20px 20px 20px;
    margin: 0 15px;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Dark-Dark, #111928);
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: 760px) {
    margin-bottom: 0px;
  }
`;

export const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 37px;
  background: var(--Red-Red-light-5, #feebeb);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;

  img {
    width: 30px;
    height: 30px;
  }
`;

export const Description = styled.div`
  color: var(--Text-secondary, #4a4e62);
  text-align: center;
  /* Body/Reg */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`;

export const ButtonCancel = styled(CommonButton)`
  max-width: 190px;
  padding: 0 40px;

  @media (max-width: 760px) {
    width: 45%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  width: 100%;

  @media (max-width: 760px) {
    gap: 18px;
  }
`;

export const ButtonDelete = styled(ButtonCancel)`
  min-width: 190px !important;
  width: max-content;
  @media (max-width: 760px) {
    padding: 16px 26px;
    min-width: unset !important;
    max-width: 70% !important;
  }
`;
