import styled from 'styled-components';
import BackgroundHeroImg from 'assets/back_hero.png';
import { CommonTextH1, CommonTextH5 } from 'components/common/styles';

export const Container = styled.div`
  background-color: #ebeeff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding-bottom: 80px;
  position: relative;
  min-height: 580px;
  background: url(${BackgroundHeroImg}) no-repeat;
  background-size: cover;

  .ant-upload {
    padding: 0;
  }
  @media (max-width: 760px) {
    padding-bottom: 40px;
    min-height: auto;
  }
`;

export const HeroHeaderLayout = styled.div`
  flex: 1 0 0;
  max-width: 1020px;
  margin: 100px 0 20px 0;

  @media (max-width: 760px) {
    padding: 10px 24px 15px 25px;
    margin: 0;
  }
`;

export const HeroHeader = styled(CommonTextH1)`
  text-align: center;
`;

export const HeroSecondaryHeader = styled(CommonTextH5)`
  color: var(--Text-secondary, #4c5e7f);
  text-align: center;
  align-self: stretch;
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;

  @media (max-width: 760px) {
    padding: 0px 25px 25px 25px;
    margin-bottom: 0;
  }
`;

export const HeroButtonDescription = styled.span`
  color: var(--Text-color-subtitle, #4c5e7f);
  text-align: center;
  /* Body Large/Reg */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 10px;

  @media (max-width: 760px) {
    display: none;
  }
`;

export const GroupImage = styled.img`
  width: 22.688px;
  height: 22.688px;

  @media (max-width: 760px) {
    display: none;
  }
`;

export const CookiesBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
`;
