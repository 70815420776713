export const isEmailValid = (email: string): boolean => {
  return /^(?=\S+$)(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@([\w-]+\.)+[\w-]{2,8}$/ // eslint-disable-line
    .test(email);
};

export const isValidPassword = (password: string): string => {
  if (password.length < 8) return 'Password must contain at least 8 characters';
  if (password.length > 50) return 'Password must contain no more than 50 characters';
  return '';
};
