import { createSelector } from 'reselect';

const baseLoadingSelector = (state: any) => state?.documents;

export const uploadProgressSelector = () =>
  createSelector(baseLoadingSelector, (documents) => documents?.downloadProgress || 0);

export const convertDocumentDataSelector = () =>
  createSelector(baseLoadingSelector, (documents) => {
    if (!!documents?.convertDocumentData) return documents?.convertDocumentData;
    const dataToConvert = localStorage.getItem('dataToConvert');

    if (dataToConvert && !documents?.convertDocumentData) {
      try {
        const data = JSON.parse(dataToConvert);
        return data;
      } catch (err) {}
    }

    return documents?.convertDocumentData;
  });

export const editFilenameSelector = () =>
  createSelector(baseLoadingSelector, (documents) => documents?.editFilename || '');

export const editDocumentDataSelector = () =>
  createSelector(baseLoadingSelector, (documents) => {
    const dataToEdit = localStorage.getItem('dataToEdit');

    if (dataToEdit && !documents?.editDocumentData) {
      try {
        const data = JSON.parse(dataToEdit);
        return data;
      } catch (err) {}
    }

    return documents?.editDocumentData;
  });

export const pdfFileContentSelector = () =>
  createSelector(baseLoadingSelector, (documents) => documents?.pdfFileContent);

export const documentByIdSelector = () =>
  createSelector(baseLoadingSelector, (documents) => documents?.documentById);

export const documentsDataSelector = () =>
  createSelector(baseLoadingSelector, (documents) => documents?.documents?.data || []);

export const mergeDocumentsListSelector = () =>
  createSelector(baseLoadingSelector, (documents) => documents?.mergeDocumentsList);
