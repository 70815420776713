import { useSelector } from 'react-redux';

import { modalsSelector } from 'data/selectors/modals';
import { EModalTypes } from 'ts/enums/modal.types';
import AuthForm from 'layouts/authForm';
import DeleteDocumentModal from 'components/modals/deleteDocumentModal';
import ProgressFileModal from 'components/modals/progressFileModal';
import EnterEmailAddressModal from 'components/modals/enterEmailAddressModal';
import CancelPlanModal from 'components/modals/cancelPlanModal';
import CheckYourEmailModal from 'components/modals/checkYourEmailModal';
import RecoverPasswordModal from 'components/modals/recoverPasswordModal';
import PaymentErrorModal from 'components/modals/paymentErrorModal';
import FileUploadErrorModal from 'components/modals/fileErrorModal';
import ThankYouPaymentModal from 'components/modals/thankYouPaymentModal';
import DragAndDropModal from 'components/modals/drag&dropModal';
import ChooseFormatModal from 'components/modals/chooseFormatModal';
import ProgressEditFileModal from 'components/modals/progressEditFileModal';
import ConvertingFileModal from 'components/modals/convertingFileModal';
import ChooseFormatAndConvertModal from 'components/modals/chooseFormatAndConvertModal';
import ProcessingCompressModal from 'components/modals/processingCompressModal';
import ProcessingSplitModal from 'components/modals/processingSplitModal';
import TrustPilotModal from 'components/modals/trustPilotModal';
import ProcessingMergeModal from 'components/modals/processingMergeModal';
import ThankSubscriptionOfferModal from 'components/modals/thankSubscriptionOfferModal';
import GetFreeAccessModal from 'components/modals/getFreeAccessModal';

const ModalsController = () => {
  const { type } = useSelector(modalsSelector);

  switch (type) {
    case EModalTypes.AUTH_FORM: {
      return <AuthForm />;
    }
    case EModalTypes.PROGRESS_FILE: {
      return <ProgressFileModal />;
    }
    case EModalTypes.PROGRESS_EDIT_FILE: {
      return <ProgressEditFileModal />;
    }
    case EModalTypes.ENTER_EMAIL_ADDRESS: {
      return <EnterEmailAddressModal />;
    }
    case EModalTypes.CANCEL_PLAN: {
      return <CancelPlanModal />;
    }
    case EModalTypes.DELETE_DOCUMENT: {
      return <DeleteDocumentModal />;
    }
    case EModalTypes.CHECK_YOUR_EMAIL: {
      return <CheckYourEmailModal />;
    }
    case EModalTypes.RECOVER_PASSWORD: {
      return <RecoverPasswordModal />;
    }
    case EModalTypes.PAYMENT_ERROR: {
      return <PaymentErrorModal />;
    }
    case EModalTypes.FILE_UPLOAD_ERROR: {
      return <FileUploadErrorModal />;
    }

    case EModalTypes.THANK_YOU_PAYMENT: {
      return <ThankYouPaymentModal />;
    }

    case EModalTypes.THANK_SUBSCRIPTION_OFFER_MODAL: {
      return <ThankSubscriptionOfferModal />;
    }

    case EModalTypes.DARG_AND_DROP: {
      return <DragAndDropModal />;
    }

    case EModalTypes.CHOOSE_FORMAT: {
      return <ChooseFormatModal />;
    }

    case EModalTypes.CONVERTING_FILE: {
      return <ConvertingFileModal />;
    }

    case EModalTypes.CHOOSE_FORMAT_AND_CONVERT: {
      return <ChooseFormatAndConvertModal />;
    }

    case EModalTypes.PROCESSING_COMPRESS: {
      return <ProcessingCompressModal />;
    }

    case EModalTypes.PROCESSING_SPLIT: {
      return <ProcessingSplitModal />;
    }

    case EModalTypes.TRUST_PILOT: {
      return <TrustPilotModal />;
    }

    case EModalTypes.PROCESSING_MERGE: {
      return <ProcessingMergeModal />;
    }

    case EModalTypes.GET_FREE_ACCESS: {
      return <GetFreeAccessModal />;
    }

    default:
      return null;
  }
};

export default ModalsController;
