import thunk from 'redux-thunk';
import { createStore, applyMiddleware, Store, Dispatch, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import rootReducer from './reducers/rootReducer';
import createApiMiddleware from './middleware/api';

const apiMiddleware = createApiMiddleware();
export default function configureStore(initialState: any = {}): Store<any, IReduxAction> & {
  dispatch: Dispatch;
} {
  const composeWithOptions =
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools({ trace: true, maxAge: 100, traceLimit: 200 })
      : compose;

  return createStore(
    rootReducer,
    initialState,
    composeWithOptions(applyMiddleware(thunk, apiMiddleware))
  );
}
