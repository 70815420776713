import { identify, Identify, init, setUserId, track } from '@amplitude/analytics-browser'
import { AnalyticsEvent } from "services/analytics/events"
import {
  Analytics,
  AnalyticsService,
  AnalyticsUserProp,
} from "services/analytics/index"
import { getMarketingValues } from 'utils/storeMarketingValues'
import { getDeviceType } from 'utils/getDeviceType'

export class AmplitudeAnalytics implements AnalyticsService {
  private initialized = false;
  private eventBuffer: AnalyticsEvent[] = [];
  private propsBuffer: AnalyticsUserProp[] = [];
  private userId: string | null = null;

  public init() {
    const apiKey = process.env.REACT_APP_AMPLITUDE_KEY;
    if (!apiKey) return;

    init(apiKey, {
      defaultTracking: false,
    });
    this.initialized = true;

    const marketingValues = getMarketingValues();
    const userData: AnalyticsUserProp[] = [];
    if (marketingValues) {
      for (const [key, value] of Object.entries(marketingValues)) {
        userData.push({ key, value } as AnalyticsUserProp);
      }
    }

    userData.push({
      key: 'screen_size',
      value: `${window.innerWidth}_${window.innerHeight}`,
    });
    this.updateUser(userData);
    this.processBuffer();
  }

  public track(event: AnalyticsEvent) {
    if (!this.initialized) {
      this.eventBuffer.push(event);
      return;
    }

    this.sendEvent(event);
  }

  public updateUser(data: AnalyticsUserProp | AnalyticsUserProp[]) {
    if (!this.initialized) {
      this.propsBuffer = Array.isArray(data)
        ? [...this.propsBuffer, ...data]
        : [...this.propsBuffer, data];
      return;
    }

    this.sendUserProps(data);
  }

  public setUserId(userId: string | null) {
    if (userId === null) return;
    if (!this.initialized) {
      this.userId = userId;
      return;
    }

    setUserId(userId);
  }

  public setDeviceType(device: string | null) {
    if (device !== null) {
      this.sendUserProps({ key: 'first_open_device', value: device });
    }
  }

  private processBuffer() {
    this.eventBuffer.map((event) => this.sendEvent(event));
    this.eventBuffer = [];
    this.propsBuffer.map((props) => this.sendUserProps(props));
    this.propsBuffer = [];
    this.setUserId(this.userId);
  }

  private sendEvent(event: AnalyticsEvent) {
    const page = Analytics.getCurrentPageName();
    const localPage = Analytics.getCurrentPageLocale();
    const deviceType = getDeviceType();
    let props = { page, local_page: localPage, device: deviceType };

    if ('data' in event && event.data) {
      props = { ...props, ...event.data };
    }

    track(event.event, props)
      .promise.then((res) => {
      });
  }

  private sendUserProps(data: AnalyticsUserProp | AnalyticsUserProp[]) {
    const identifyInstance = new Identify();
    const update = (prop: AnalyticsUserProp) => {
      if (prop.key === 'first_open_device') {
        identifyInstance.setOnce(prop.key, prop.value);
        return;
      }

      identifyInstance.set(prop.key, prop.value);
    };

    if (Array.isArray(data)) {
      data.map((prop) => update(prop));
      identify(identifyInstance)
        .promise.then((res) => {
        })

      return;
    }

    update(data);
    identify(identifyInstance)
      .promise.then((res) => { })
  }
}

export const Amplitude = new AmplitudeAnalytics();
