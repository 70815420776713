import { Middleware } from 'redux';
import { triggerLoadingAction } from 'data/actions/loading';
import i18n from 'i18next';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import axios, { AxiosResponse } from 'axios';
import openNotification from 'components/commonNotification';
import { ENotification } from 'ts/interfaces/common/notification';

const apiMiddleware = (): Middleware => (store) => (next: any) => async (action: IReduxAction) => {
  if (!action) return false;

  const host = action?.payload?.host;
  const forwardedData = action?.forwardedData;
  const event = action?.event;
  const endpoint = action?.payload?.endpoint;
  const method = action?.payload?.method;
  const customUrl = action?.payload?.customUrl;
  const customHeaders = action?.payload?.customHeaders;
  const body = action?.payload?.body;
  const analyticEvent = action?.analyticEvent;
  const params = action?.payload?.params;
  const data = action?.data;
  const components = action?.components;
  const delayLoading = action?.delayLoading;
  const onSuccess = action?.onSuccess;
  const onFailed = action?.onFailed;

  const apiHost = host ? host : process.env.REACT_APP_API_URL;

  if ((data && !endpoint) || event || analyticEvent) return next(action);

  let loading = true;
  setTimeout(() => {
    if (loading && components) {
      store.dispatch(triggerLoadingAction(components, true));
    }
  }, delayLoading);

  let headers: any = customHeaders || {
    Accept: 'application/json',
  };

  headers['x-pdf-app'] = 'BESTPDF';

  try {
    const res: AxiosResponse = await axios({
      url: customUrl || `${apiHost}/${endpoint}`,
      method,
      data: body,
      params,
      headers,
      withCredentials: true,
    });

    const data = res?.data;
    const responseHeaders = res?.headers;

    if (onSuccess) onSuccess(data);

    loading = false;
    if (components) setTimeout(() => store.dispatch(triggerLoadingAction(components, false)), 0);

    return next({
      ...action,
      payload: data,
      headers: responseHeaders,
      forwardedData,
    });
  } catch (err: any) {
    loading = false;
    const status = err?.response?.status;

    if (status === 500) {
      openNotification({
        type: ENotification.ERROR,
        message: i18n.t('notifications.internal_error'),
      });
    }

    if (components) setTimeout(() => store.dispatch(triggerLoadingAction(components, false)), 0);
    if (onFailed) return onFailed(err);

    return false;
  }
};

export default apiMiddleware;
