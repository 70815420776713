export enum EReduxTypes {
  CREATE_ANONYMOUS_USER = 'CREATE_ANONYMOUS_USER',
  CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD',
  CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL',
  SET_USER_EMAIL = 'SET_USER_EMAIL',
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  GOOGLE_AUTH = 'GOOGLE_AUTH',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  GET_DOCUMENTS = 'GET_DOCUMENTS',
  GET_CONVERTED_FILE = 'GET_CONVERTED_FILE',
  GET_OPTIMIZED_FILE = 'GET_OPTIMIZED_FILE',
  GET_SPLITTED_FILE = 'GET_SPLITTED_FILE',
  GET_MERGED_FILE = 'GET_MERGED_FILE',
  GET_DOCUMENT_BY_ID = 'GET_DOCUMENT_BY_ID',
  DELETE_DOCUMENT_BY_ID = 'DELETE_DOCUMENT_BY_ID',
  GET_UPLOAD_LINK = 'GET_UPLOAD_LINK',
  DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
  DOWNLOAD_AND_CONVERT_TO_BUFFER = 'DOWNLOAD_AND_CONVERT_TO_BUFFER',
  CONVERT_DOCUMENT = 'CONVERT_DOCUMENT',
  MERGE_DOCUMENT = 'MERGE_DOCUMENT',
  CONVERT_EXISTING_DOCUMENT = 'CONVERT_EXISTING_DOCUMENT',
  SPLIT_EXISTING_DOCUMENT = 'SPLIT_EXISTING_DOCUMENT',
  SPLIT_DOCUMENT = 'SPLIT_DOCUMENT',
  UPLOAD_EDIT_DOCUMENT = 'UPLOAD_EDIT_DOCUMENT',
  SAVE_EDIT_DOCUMENT = 'SAVE_EDIT_DOCUMENT',
  UPDATE_EDIT_FILENAME = 'UPDATE_EDIT_FILENAME',
  SET_CONVERT_DOCUMENT_DATA = 'SET_CONVERT_DOCUMENT_DATA',
  SET_MERGE_DOCUMENT_DATA = 'SET_MERGE_DOCUMENT_DATA',
  UPDATE_MERGE_DOCUMENT_IMAGE = 'UPDATE_MERGE_DOCUMENT_IMAGE',
  PUSH_MERGE_DOCUMENT_DATA = 'PUSH_MERGE_DOCUMENT_DATA',
  SET_EDIT_DOCUMENT_DATA = 'SET_EDIT_DOCUMENT_DATA',
  UPDATE_EDIT_DOCUMENT_DATA = 'UPDATE_EDIT_DOCUMENT_DATA',
  UPDATE_CONVERT_DOCUMENT_DATA = 'UPDATE_CONVERT_DOCUMENT_DATA',
  SET_PDF_FILE_CONTENT = 'SET_PDF_FILE_CONTENT',
  GET_PLANS = 'GET_PLANS',
  GET_USER_COUNTRY = 'GET_USER_COUNTRY',
  INIT_PAYMENT_CARD = 'INIT_PAYMENT_CARD',
  INIT_USER_SUBSCRIPTION = 'INIT_USER_SUBSCRIPTION',
  UPDATE_DOWNLOAD_PROGRESS = 'UPDATE_DOWNLOAD_PROGRESS',
  CONVERT_ANONYMOUS = 'CONVERT_ANONYMOUS',
  UPDATE_LOADING_COMPONENT = 'UPDATE_LOADING_COMPONENT',
  GET_USER_CREDITS = 'GET_USER_CREDITS',
  CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
  PASSWORD_RESET = 'PASSWORD_RESET',
  SEND_FORGOT_PASSWORD = 'SEND_FORGOT_PASSWORD',
  GET_USER = 'GET_USER',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  GET_USER_SUBSCRIPTION = 'GET_USER_SUBSCRIPTION',
  GET_ORDER_LIST = 'GET_ORDER_LIST',
  SET_SELECTED_PLAN_ID = 'SET_SELECTED_PLAN_ID',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  RESTORE_SUBSCRIPTION = 'RESTORE_SUBSCRIPTION',
  SET_ORDER_DATA = 'SET_ORDER_DATA',
  SET_PAGE_VIEW = 'SET_PAGE_VIEW',
  GET_TRUST_PILOT_LINK = 'GET_TRUST_PILOT_LINK',
  SWITCH_SUBSCRIPTION = 'SWITCH_SUBSCRIPTION',
  GET_FREE_ACCESS = 'GET_FREE_ACCESS',
  CONVERTING_EVENT = "CONVERTING_EVENT",
  SEND_ANALYTIC_EVENT = "SEND_ANALYTIC_EVENT",
}
