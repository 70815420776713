import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { ISubscription } from 'ts/interfaces/subscriptions/subscriptions';

import { getCurrencySymbol } from 'helpers/getCurrency';
import { initialPlans, initialPlansRecurrentPrice, initialTestBPlans } from 'helpers/generatePlanData';

const initialState: ISubscription = {
  subscription: null,
  selectedPlan: null,
  plans: initialPlans,
  plansTestB: initialTestBPlans,
  plansRecurrent: initialPlansRecurrentPrice,
  offerPlan: null,
  offerPlanTestB: null,
  order: null,
};

const subscriptionsReducer = (state = initialState, action: IReduxAction): ISubscription | {} => {
  switch (action.type) {
    case EReduxTypes.SIGN_OUT: {
      return initialState;
    }

    case EReduxTypes.SET_SELECTED_PLAN_ID: {
      return {
        ...state,
        selectedPlan: action.data.plan,
      };
    }

    case EReduxTypes.SET_ORDER_DATA: {
      return {
        ...state,
        order: action.data,
      };
    }

    case EReduxTypes.GET_PLANS: {
      const newPlans = [...state?.plans];
      const newPlansRecurrent = [...state?.plansRecurrent];

      const productsList = (action?.payload || [])?.filter((item: any) =>
        item?.name?.includes('the_best_pdf')
      );

      for (let i = 0; i < state?.plans?.length; i++) {
        const product = state?.plans[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }
        }
      }

      for (let i = 0; i < state?.plansRecurrent?.length; i++) {
        const product = state?.plansRecurrent[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }
        }
      }
      return {
        ...state,
        plans: newPlans,
        plansRecurrent: newPlansRecurrent,
        offerPlan:
          (action?.payload || [])?.find(
            (item: any) => item?.name === 'the_best_pdf_monthly_49_95_70%_discount_14_99'
          ) || null,
        offerPlanTestB: (action?.payload || [])?.find(
            (item: any) => item?.name === 'the_best_pdf_monthly_39_95_70%_discount'
          ) || null,
      };
    }
    default:
      return state;
  }
};

export default subscriptionsReducer;
