import lottie from 'lottie-web/build/player/lottie_light';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import animationData from 'assets/animations/loading-animation.json';
import useDisableScroll from 'hooks/useDisableScroll';
import { ModalContainer } from '../baseModal/styles';
import { Container, Description } from './styles';

const ThankYouPaymentModal: FC = () => {
  const animationRef = useRef(null);
  const { t } = useTranslation();
  useDisableScroll();

  useEffect(() => {
    if (!animationRef.current || typeof window === 'undefined') return;

    lottie?.loadAnimation({
      container: animationRef?.current,
      renderer: 'svg',
      name: 'buttonHover',
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        className: 'lottie-svg-button-upload',
      },
    });

    return () => {
      lottie?.destroy();
    };
  }, []);

  return (
    <ModalContainer>
      <Container>
        <div ref={animationRef} />
        <Description>{t('global.thank_you_payment')}</Description>
      </Container>
    </ModalContainer>
  );
};

export default ThankYouPaymentModal;
