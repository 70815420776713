import { useFeature } from "@growthbook/growthbook-react";
import { getJsonFomStorage } from "helpers/localStorageHelper";
import {
  GrowthBookTestType,
  LocalStorageProperties,
} from "ts/enums/growthbook";

export const useCheckoutTestValue = (): LocalStorageProperties => {
  const abTests = getJsonFomStorage('abTests')
  const testingCheckoutAB = useFeature(GrowthBookTestType.NEW_PAYMENT_SCREEN);

  if (abTests?.includes(LocalStorageProperties.NEW_CHECKOUT_SCREEN_B)) return LocalStorageProperties.NEW_CHECKOUT_SCREEN_B

  if (testingCheckoutAB?.experiment?.key && testingCheckoutAB?.experimentResult?.value) {
    return `${testingCheckoutAB?.experiment?.key}_${testingCheckoutAB?.experimentResult?.value}` as LocalStorageProperties
  }

  return LocalStorageProperties.NEW_CHECKOUT_SCREEN_A
}