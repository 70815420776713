import { CardPaymentError, errorCodeMap } from 'ts/enums/paymentForm';
import iconCardPath from 'assets/errors/error-card.svg';
import iconCirclePath from 'assets/errors/error-circle.svg';
import iconClosePath from 'assets/errors/error-close.svg';
import iconShieldPath from 'assets/errors/error-shield.svg';
// import iconSquarePath from 'assets/errors/error-square.svg'
import iconWarningPath from 'assets/errors/error-warning.svg';

export const getIconPath = (errorCode: string) => {
  switch (errorCodeMap[errorCode]) {
    case CardPaymentError.INVALID_CARD_NUMBER:
    case CardPaymentError.INVALID_EXPIRATION_DATE:
      return iconCardPath;
    case CardPaymentError.INSUFFICIENT_FUNDS:
    case CardPaymentError.INVALID_CVV_CODE:
      return iconCirclePath;
    case CardPaymentError.DEBIT_CARD_NOT_SUPPORTED:
      return iconClosePath;
    case CardPaymentError.SCA_ENGINE_ERROR:
      return iconWarningPath;
    case CardPaymentError.THREE_D_SECURE:
      return iconShieldPath;
    case CardPaymentError.SUSPECTED_FRAUD:
    case CardPaymentError.DO_NOT_HONOR:
    case CardPaymentError.DECLINED_BY_ISSUER:
    case CardPaymentError.CARD_IS_BLOCKED:
    case CardPaymentError.GENERAL_DECLINE:
    case CardPaymentError.ANTIFRAUD_SERVICE:
    case CardPaymentError.INVALID_TRANSACTION:
    case CardPaymentError.STOLEN_CARD:
    case CardPaymentError.UNKNOWN_DECLINE_CODE:
    case CardPaymentError.ORDER_EXPIRED:
    case CardPaymentError.ILLEGAL_OPERATION:
    case CardPaymentError.AUTHENTICATION_FAILED:
    case CardPaymentError.INVALID_DATA:
    case CardPaymentError.INVALID_AMOUNT:
    case CardPaymentError.INVALID_CURRENCY:
    case CardPaymentError.ORDER_NOT_FOUND:
    case CardPaymentError.REQUEST_IS_EMPTY:
    case CardPaymentError.INVALID_THREE_DS_FLOW_MERCHANT_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW_BANK_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW:
    case CardPaymentError.INVALID_IP:
    case CardPaymentError.SUBSCRIPTION_ERROR:
    case CardPaymentError.SCA_REQUIRE_THREE_D_AUTHENTICATION:
    case CardPaymentError.SUBSCRIPTION_IS_LOCKED:
    case CardPaymentError.PAYMENT_AMOUNT_LIMIT_EXCESS:
    case CardPaymentError.DECLINED_BY_THE_ISSUER:
    case CardPaymentError.CALL_YOUR_BANK:
    case CardPaymentError.THREE_D_SECURE_AUTH_REQUIRED:
    case CardPaymentError.RECURRING_PAYMENT_CANCELLED:
    case CardPaymentError.CARD_IS_IN_A_BANK_LIST:
    case CardPaymentError.LOST_CARD:
    case CardPaymentError.PSP_FRAUD:
    case CardPaymentError.BLOCKED_BY_COUNTRY_IP:
    case CardPaymentError.TRUSTED_ANTIFRAUD_SYSTEM:
    case CardPaymentError.AVS_MISMATCH:
    case CardPaymentError.INVALID_CARD_TOKEN:
    case CardPaymentError.APPLICATION_ERROR:
    case CardPaymentError.MERCH_IS_NOT_CONFIG_CORRECTLY:
    case CardPaymentError.MERCH_IS_NOT_ACTIVATED_YET:
    case CardPaymentError.DUPLICATE_ORDER:
    case CardPaymentError.EXCEEDED_API_CALLS_LIMITS:
    case CardPaymentError.MERCH_NOT_FOUND:
    case CardPaymentError.PROCESSOR_NOT_SUPPORT_API:
    case CardPaymentError.INVALID_ROUTING:
    case CardPaymentError.ACCOUNT_IS_BLOCKED:
    case CardPaymentError.CONNECTION_ERROR:
    case CardPaymentError.CARD_TOKEN_NOT_FOUND:
    case CardPaymentError.GOOGLE_PAYMENT_ERROR:
    case CardPaymentError.SMART_CASCADE_DECLINE:
    case CardPaymentError.THREE_D_CASCADE_TO_TWO_D:
    case CardPaymentError.APPLE_ONLINE_PAYMENT_ERROR:
    case CardPaymentError.TOKEN_GENERATION_ERROR:
    case CardPaymentError.CARD_BRAND_NOT_SUPPORTED:
    case CardPaymentError.RESTRICTED_CARD:
      return iconCardPath;
    default:
      return iconCardPath;
  }
};

export const getErrorText = (errorCode: string, t: any) => {
  switch (errorCodeMap[errorCode]) {
    case CardPaymentError.DEBIT_CARD_NOT_SUPPORTED:
      return t('card_error_popup.no_support_card.text');
    case CardPaymentError.SCA_ENGINE_ERROR:
      return t('card_error_popup.incorrect_expiration_date.text');
    case CardPaymentError.CARD_BRAND_NOT_SUPPORTED:
      return t('card_error_popup.no_support_card.text');
    case CardPaymentError.THREE_D_SECURE:
      return t('card_error_popup.3d_secure.text');
    case CardPaymentError.SUSPECTED_FRAUD:
    case CardPaymentError.DO_NOT_HONOR:
    case CardPaymentError.DECLINED_BY_ISSUER:
    case CardPaymentError.CARD_IS_BLOCKED:
    case CardPaymentError.GENERAL_DECLINE:
    case CardPaymentError.ANTIFRAUD_SERVICE:
    case CardPaymentError.INVALID_TRANSACTION:
    case CardPaymentError.STOLEN_CARD:
    case CardPaymentError.UNKNOWN_DECLINE_CODE:
    case CardPaymentError.ORDER_EXPIRED:
    case CardPaymentError.ILLEGAL_OPERATION:
    case CardPaymentError.AUTHENTICATION_FAILED:
    case CardPaymentError.INVALID_DATA:
    case CardPaymentError.INVALID_AMOUNT:
    case CardPaymentError.INVALID_CURRENCY:
    case CardPaymentError.ORDER_NOT_FOUND:
    case CardPaymentError.REQUEST_IS_EMPTY:
    case CardPaymentError.INVALID_THREE_DS_FLOW_MERCHANT_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW_BANK_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW:
    case CardPaymentError.INVALID_IP:
    case CardPaymentError.SUBSCRIPTION_ERROR:
    case CardPaymentError.SCA_REQUIRE_THREE_D_AUTHENTICATION:
    case CardPaymentError.SUBSCRIPTION_IS_LOCKED:
    case CardPaymentError.PAYMENT_AMOUNT_LIMIT_EXCESS:
    case CardPaymentError.DECLINED_BY_THE_ISSUER:
    case CardPaymentError.CALL_YOUR_BANK:
    case CardPaymentError.THREE_D_SECURE_AUTH_REQUIRED:
    case CardPaymentError.RECURRING_PAYMENT_CANCELLED:
    case CardPaymentError.CARD_IS_IN_A_BANK_LIST:
    case CardPaymentError.LOST_CARD:
    case CardPaymentError.PSP_FRAUD:
    case CardPaymentError.BLOCKED_BY_COUNTRY_IP:
    case CardPaymentError.TRUSTED_ANTIFRAUD_SYSTEM:
    case CardPaymentError.AVS_MISMATCH:
    case CardPaymentError.INVALID_CARD_TOKEN:
    case CardPaymentError.APPLICATION_ERROR:
    case CardPaymentError.MERCH_IS_NOT_CONFIG_CORRECTLY:
    case CardPaymentError.MERCH_IS_NOT_ACTIVATED_YET:
    case CardPaymentError.DUPLICATE_ORDER:
    case CardPaymentError.EXCEEDED_API_CALLS_LIMITS:
    case CardPaymentError.MERCH_NOT_FOUND:
    case CardPaymentError.PROCESSOR_NOT_SUPPORT_API:
    case CardPaymentError.INVALID_ROUTING:
    case CardPaymentError.ACCOUNT_IS_BLOCKED:
    case CardPaymentError.CONNECTION_ERROR:
    case CardPaymentError.CARD_TOKEN_NOT_FOUND:
    case CardPaymentError.GOOGLE_PAYMENT_ERROR:
    case CardPaymentError.SMART_CASCADE_DECLINE:
    case CardPaymentError.THREE_D_CASCADE_TO_TWO_D:
    case CardPaymentError.APPLE_ONLINE_PAYMENT_ERROR:
    case CardPaymentError.TOKEN_GENERATION_ERROR:
      return t('card_error_popup.error_processing_order.text');
    case CardPaymentError.INSUFFICIENT_FUNDS:
      return t('card_error_popup.insufficient_funds_for_payment.text');
    case CardPaymentError.RESTRICTED_CARD:
      return t('card_error_popup.incorrect_expiration_date.text');
    case CardPaymentError.INVALID_CVV_CODE:
      return t('card_error_popup.incorrect_cvv_code.text');
    case CardPaymentError.INVALID_CARD_NUMBER:
      return t('card_error_popup.wrong_card_number.text');
    case CardPaymentError.INVALID_EXPIRATION_DATE:
      return t('card_error_popup.incorrect_expiration_date.text');
    default:
      return t('card_error_popup.error_processing_order.text');
  }
};

export const getErrorTitle = (errorCode: string, t: any) => {
  switch (errorCodeMap[errorCode]) {
    case CardPaymentError.DEBIT_CARD_NOT_SUPPORTED:
      return t('card_error_popup.no_support_card.title');
    case CardPaymentError.SCA_ENGINE_ERROR:
      return t('card_error_popup.error_processing_order.title');
    case CardPaymentError.CARD_BRAND_NOT_SUPPORTED:
      return t('card_error_popup.card_brand_not_supported.title');
    case CardPaymentError.THREE_D_SECURE:
      return t('card_error_popup.3d_secure.title');
    case CardPaymentError.SUSPECTED_FRAUD:
    case CardPaymentError.DO_NOT_HONOR:
    case CardPaymentError.DECLINED_BY_ISSUER:
    case CardPaymentError.CARD_IS_BLOCKED:
    case CardPaymentError.GENERAL_DECLINE:
    case CardPaymentError.ANTIFRAUD_SERVICE:
    case CardPaymentError.INVALID_TRANSACTION:
    case CardPaymentError.STOLEN_CARD:
    case CardPaymentError.UNKNOWN_DECLINE_CODE:
    case CardPaymentError.ORDER_EXPIRED:
    case CardPaymentError.ILLEGAL_OPERATION:
    case CardPaymentError.AUTHENTICATION_FAILED:
    case CardPaymentError.INVALID_DATA:
    case CardPaymentError.INVALID_AMOUNT:
    case CardPaymentError.INVALID_CURRENCY:
    case CardPaymentError.ORDER_NOT_FOUND:
    case CardPaymentError.REQUEST_IS_EMPTY:
    case CardPaymentError.INVALID_THREE_DS_FLOW_MERCHANT_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW_BANK_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW:
    case CardPaymentError.INVALID_IP:
    case CardPaymentError.SUBSCRIPTION_ERROR:
    case CardPaymentError.SCA_REQUIRE_THREE_D_AUTHENTICATION:
    case CardPaymentError.SUBSCRIPTION_IS_LOCKED:
    case CardPaymentError.PAYMENT_AMOUNT_LIMIT_EXCESS:
    case CardPaymentError.DECLINED_BY_THE_ISSUER:
    case CardPaymentError.CALL_YOUR_BANK:
    case CardPaymentError.THREE_D_SECURE_AUTH_REQUIRED:
    case CardPaymentError.RECURRING_PAYMENT_CANCELLED:
    case CardPaymentError.CARD_IS_IN_A_BANK_LIST:
    case CardPaymentError.LOST_CARD:
    case CardPaymentError.PSP_FRAUD:
    case CardPaymentError.BLOCKED_BY_COUNTRY_IP:
    case CardPaymentError.TRUSTED_ANTIFRAUD_SYSTEM:
    case CardPaymentError.AVS_MISMATCH:
    case CardPaymentError.INVALID_CARD_TOKEN:
    case CardPaymentError.APPLICATION_ERROR:
    case CardPaymentError.MERCH_IS_NOT_CONFIG_CORRECTLY:
    case CardPaymentError.MERCH_IS_NOT_ACTIVATED_YET:
    case CardPaymentError.DUPLICATE_ORDER:
    case CardPaymentError.EXCEEDED_API_CALLS_LIMITS:
    case CardPaymentError.MERCH_NOT_FOUND:
    case CardPaymentError.PROCESSOR_NOT_SUPPORT_API:
    case CardPaymentError.INVALID_ROUTING:
    case CardPaymentError.ACCOUNT_IS_BLOCKED:
    case CardPaymentError.CONNECTION_ERROR:
    case CardPaymentError.CARD_TOKEN_NOT_FOUND:
    case CardPaymentError.GOOGLE_PAYMENT_ERROR:
    case CardPaymentError.SMART_CASCADE_DECLINE:
    case CardPaymentError.THREE_D_CASCADE_TO_TWO_D:
    case CardPaymentError.APPLE_ONLINE_PAYMENT_ERROR:
    case CardPaymentError.TOKEN_GENERATION_ERROR:
    case CardPaymentError.INSUFFICIENT_FUNDS:
      return t('card_error_popup.insufficient_funds_for_payment.title');
    case CardPaymentError.RESTRICTED_CARD:
      return t('card_error_popup.incorrect_expiration_date.title');
    case CardPaymentError.INVALID_CVV_CODE:
      return t('card_error_popup.incorrect_cvv_code.title');
    case CardPaymentError.INVALID_CARD_NUMBER:
      return t('card_error_popup.wrong_card_number.title');
    case CardPaymentError.INVALID_EXPIRATION_DATE:
      return t('card_error_popup.incorrect_expiration_date.title');
    default:
      return t('card_error_popup.error_processing_order.title');
  }
};
