import { StyleProvider } from '@ant-design/cssinjs';
import { FC, lazy, Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

/** Actions */
import { getPlans } from 'data/actions/subscriptions';
import { createAnonymousUser, getUser, getUserCountry } from 'data/actions/user';

/** Selectors */
import { getLoadingSelector } from 'data/selectors/loading';
import { isUserAuthenticated } from 'data/selectors/user';

/** Providers */
import GrowthBookOwnProvider from 'providers/growthBookProvider';

/** Components */
import CookiesBar from 'components/cookiesBar';
import ModalsController from 'components/modalsController';

/** Helpers */
import { editorServicesList, mainPageService, servicesList } from 'helpers/servicesList';

/** Interfaces */
import { CookiesBarContainer } from 'sections/hero/styles';
import { IService } from 'ts/interfaces/services/service';

/** Styles */
import { GlobalStyle } from 'components/common/styles';
import GlobalFonts from 'fonts/fonts';

/** Hooks */
import useClarity from 'hooks/useClarity';
import useResetPassword from 'hooks/useResetPassword';

/** Services */
import { Amplitude } from 'services/analytics/amplitude';

/** Use cases */
import { useCheckoutTestValue } from 'hooks/growthBook/useIsNewCheckout';
import { usePricingTestValue } from 'hooks/growthBook/useIsNewPricing';
import { storeMarketingValues } from 'utils/storeMarketingValues';
// import { convertingEvent } from 'data/actions/documents';

import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';

/** Layouts */
const MainLayout = lazy(() => import('layouts/main'));

/** Pages */
const DashboardPage = lazy(() => import('pages/dashboard'));
const SubscriptionCancelLayout = lazy(() => import('layouts/subscriptionCancel'));
const NotFoundPage = lazy(() => import('pages/404'));
const AboutUsPage = lazy(() => import('pages/aboutUs'));
const CheckoutPage = lazy(() => import('pages/checkout'));
const ContactUsPage = lazy(() => import('pages/contactUs'));
const PDFEditorPage = lazy(() => import('pages/editor'));
const MainPage = lazy(() => import('pages/main'));
const MergePDFPage = lazy(() => import('pages/mergePdf'));
const MoneyBackPolicyPage = lazy(() => import('pages/moneyBackPolicy'));
const PaymentSuccess = lazy(() => import('pages/paymentSuccess'));
const PricingPage = lazy(() => import('pages/pricing'));
const PrivacyPolicyPage = lazy(() => import('pages/privacyPolicy'));
const RedirectPage = lazy(() => import('pages/redirect'));
const ServicePage = lazy(() => import('pages/service'));
const SettingsPage = lazy(() => import('pages/settings'));
const SubCancelConfirmationPage = lazy(() => import('pages/subCancelConfirmation'));
const SubscriptionOfferPage = lazy(() => import('pages/subscriptionOffer'));
const SubscriptionTermsPage = lazy(() => import('pages/subscriptionTerms'));
const TermsOfConditionsPage = lazy(() => import('pages/termsOfConditions'));

const RouterContainer: FC = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const isLoading = useSelector(getLoadingSelector('get_user'));
  const isAuth = useSelector(isUserAuthenticated);

  useResetPassword();
  useClarity();

  useCheckoutTestValue();
  usePricingTestValue();

  useEffect(() => {
    dispatch(getUserCountry());
    dispatch(getPlans());

    const onFailed = () => {
      dispatch(
        createAnonymousUser(() => {
          dispatch(getUserCountry());
          dispatch(getUser({}));
        })
      );
    };
    dispatch(getUser({ onFailed }));
  }, [dispatch]);

  useEffect(() => {
    Amplitude.init();
    storeMarketingValues();
  }, []);

  useEffect(() => {
    if (i18n?.language !== 'en' && !window.location.pathname.includes(i18n?.language)) {
      if (window.location.pathname === '/') window.location.pathname = `/${i18n?.language}`;
      else window.location.pathname = `/${i18n?.language}${window.location.pathname}`;
    }

    Amplitude.updateUser({ key: 'language', value: i18n.language || 'en' });
  }, [i18n?.language]);

  const basePath = useMemo(() => {
    return i18n?.language === 'en' ? '' : `/:${i18n?.language}`;
  }, [i18n?.language]);

  return (
    <Router>
      <StyleProvider hashPriority="high">
        <Suspense>
          <Routes>
            {isAuth && (
              <>
                <Route path={`${basePath}/app`} element={<MainLayout />}>
                  <Route index path="dashboard" element={<DashboardPage />} />
                  <Route path="settings" element={<SettingsPage />} />
                  <Route path="subscription-offer" element={<SubscriptionOfferPage />} />
                </Route>
                <Route path={`${basePath}/app`} element={<SubscriptionCancelLayout />}>
                  <Route
                    path="subscription-cancel-confirmation"
                    element={<SubCancelConfirmationPage />}
                  />
                </Route>
              </>
            )}

            {/* Start tools list */}
            {servicesList(t).map((item: IService, index) => (
              <Route
                key={`route-${item.path}-${index + 1}`}
                path={`${basePath}${item.path}`}
                element={<ServicePage service={item} />}
              />
            ))}

            {editorServicesList(t).map((item: IService, index) => (
              <Route
                key={`route-${item.path}-${index + 1}`}
                path={`${basePath}${item.path}`}
                element={<ServicePage service={item} />}
              />
            ))}
            {/* End tools list */}

            <Route path={`${basePath}/`} element={<MainPage service={mainPageService(t)} />} />
            <Route path={'redirect'} element={<RedirectPage />} />
            <Route path={`${basePath}/redirect`} element={<RedirectPage />} />
            <Route path={`${basePath}/complete-merge`} element={<MergePDFPage />} />
            <Route path={`${basePath}/editor`} element={<PDFEditorPage />} />
            <Route path={`${basePath}/contact-us`} element={<ContactUsPage />} />
            <Route path={`${basePath}/about-us`} element={<AboutUsPage />} />
            <Route
              path={`${basePath}/reset-password`}
              element={<MainPage service={mainPageService(t)} />}
            />
            <Route path={`${basePath}/choose-plan`} element={<PricingPage />} />
            <Route path={`${basePath}/checkout`} element={<CheckoutPage />} />
            <Route path={`${basePath}/checkout/success`} element={<PaymentSuccess />} />
            <Route path={`${basePath}/subscription-terms`} element={<SubscriptionTermsPage />} />
            <Route path={`${basePath}/terms`} element={<TermsOfConditionsPage />} />
            <Route path={`${basePath}/money-back-policy`} element={<MoneyBackPolicyPage />} />
            <Route path={`${basePath}/privacy-policy`} element={<PrivacyPolicyPage />} />
            {!isLoading && <Route path="*" element={<NotFoundPage />} />}
          </Routes>
        </Suspense>
        <ModalsController />
        <CookiesBarContainer>
          <CookiesBar />
        </CookiesBarContainer>
      </StyleProvider>
    </Router>
  );
};

const App: FC = () => {
  return (
    <>
      <GlobalStyle />
      <GlobalFonts />
      <GrowthBookOwnProvider>
        <RouterContainer />
      </GrowthBookOwnProvider>
    </>
  );
};

export default App;
