import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useSelector } from 'react-redux';

import { userEmailSelector, userIdSelector } from 'data/selectors/user';
import { getCookie } from './useSignUpQuery';
import { Analytics } from 'services/analytics';

const useClarity = () => {
  const userId = useSelector(userIdSelector);
  const email = useSelector(userEmailSelector) || '';

  useEffect(() => {
    if (window.location.hostname === 'localhost') return;
    clarity.init(process.env.REACT_APP_MICROSOFT_CLARITY_ID || 'nbsmmefy59');
  }, []);

  useEffect(() => {
    if (userId && clarity.hasStarted()) {
      clarity.identify(userId, { email });
      clarity.setTag('email', email);

      const clarityCookies = getCookie('_clck');

      if (clarityCookies) {
        const clarityId = clarityCookies.split('|')[0];

        Analytics.sendEvent({
          event: 'clarity_init',
          data: {
            clarityId,
          },
        });
      }
    }
  }, [userId, email]);
};

export default useClarity;
