import { useFeature } from "@growthbook/growthbook-react";
import { getJsonFomStorage } from "helpers/localStorageHelper";
import {
  GrowthBookTestType,
  LocalStorageProperties,
} from "ts/enums/growthbook";

export const usePricingTestValue = (): LocalStorageProperties => {
  const abTests = getJsonFomStorage('abTests')
  const testingPricingABC = useFeature(GrowthBookTestType.NEW_RECURRENT_PRICE);

  if (abTests?.includes(LocalStorageProperties.NEW_RECURRENT_PRICE_TEST_B)) return LocalStorageProperties.NEW_RECURRENT_PRICE_TEST_B
  else if (abTests?.includes(LocalStorageProperties.NEW_RECURRENT_PRICE_TEST_A)) return LocalStorageProperties.NEW_RECURRENT_PRICE_TEST_A

  if (testingPricingABC?.experiment?.key && testingPricingABC?.experimentResult?.value) {
    return `${testingPricingABC?.experiment?.key}_${testingPricingABC?.experimentResult?.value}` as LocalStorageProperties
  }

  return LocalStorageProperties.NEW_RECURRENT_PRICE_TEST_A
}
