import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 20px 50px 20px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 438px;
  min-height: 295px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    height: auto;
    margin: 0 15px;
  }
`;

export const Title = styled.div`
  color: var(--Text-color-tittle, #212e45);
  text-align: center;
  /* Desktop/Heading/H4 */
  font-family: Montserrat;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 29.9px */
`;

export const Description = styled.div`
  color: var(--Text-color-subtitle, #4c5e7f);
  text-align: center;
  /* Desktop/Body/Reg */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  margin: 8px 0 20px 0;
`;

export const BigStarImage = styled.img`
  margin: 10px 0;
`;
