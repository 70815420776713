import { Amplitude } from 'services/analytics/amplitude';
import { AnalyticsEvent } from 'services/analytics/events';

const defaultPaths = [
  '/',
  'choose-plan',
  'checkout',
  'checkout/success',
  'app/dashboard',
  'app/settings',
  'edit-pdf',
  'editor',
  'fill-pdf',
  'sign-pdf',
  'compress-pdf',
  'delete-pages-pdf',
  'pdf-converter',
  'merge-pdf',
  'complete-merge',
  'split-pdf',
  'contact-us',
  'about-us',
  'app/subscription-cancel-confirmation',
  'app/subscription-offer',
  'privacy-policy',
  'subscription-terms',
  'terms',
  'money-back-policy',
];

export const Analytics = {
  sendEvent: async (event: AnalyticsEvent) => {
    Amplitude.track(event);
  },
  updateUser: (data: AnalyticsUserProp | AnalyticsUserProp[]) => {
    Amplitude.updateUser(data);
  },
  setUserId: (userId: string) => {
    Amplitude.setUserId(userId);
  },
  getCurrentPageName: () => {
    const path = window.location.pathname;
    const woSlash = path.replace('/', '');

    const locales = ['fr', 'de', 'it'];

    const woLocales = locales.includes(woSlash.slice(0, 2)) ? `${woSlash.slice(3)}` : woSlash;

    // converters
    if (path.includes('-to-')) {
      return woLocales.replace(/-/g, '_');
    }

    if (defaultPaths.includes(woLocales)) {
      return woLocales;
    }

    if (window.location.pathname.includes('reset-password')) {
      return 'restore';
    }

    switch (window.location.pathname) {
      case '/':
        return 'main_page';
      case '/fr':
      case '/fr/':
        return '/fr/main_page';
      case '/de':
      case '/de/':
        return '/de/main_page';
      case '/it':
      case '/it/':
        return '/it/main_page';
      default:
        return '404';
    }
  },
  getCurrentPageLocale: () => {
    const path = window.location.pathname;
    const woSlash = path.replace('/', '');

    const locales = ['fr', 'de', 'it'];

    const woLocales = locales.includes(woSlash.slice(0, 2)) ? `${woSlash.slice(3)}` : woSlash;

    // converters
    if (path.includes('-to-')) {
      return woSlash.replace(/-/g, '_');
    }

    if (defaultPaths.includes(woLocales)) {
      return woSlash;
    }

    if (window.location.pathname.includes('reset-password')) {
      return 'restore';
    }

    switch (window.location.pathname) {
      case '/':
        return 'main_page';
      case '/fr':
      case '/fr/':
        return '/fr/main_page';
      case '/de':
      case '/de/':
        return '/de/main_page';
      case '/it':
      case '/it/':
        return '/it/main_page';
      default:
        return '404';
    }
  },
};

export interface AnalyticsService {
  init: () => void;
  track: (event: AnalyticsEvent) => void;
  updateUser: (data: AnalyticsUserProp | AnalyticsUserProp[]) => void;
  setUserId: (userId: string) => void;
}

export interface AnalyticsUserProp {
  key: string;
  value: string | number | boolean | string[];
}
